import "@fontsource/cutive-mono";

import {
  Button,
  Card,
  Center,
  Container,
  createTheme,
  Grid,
  Group,
  Image,
  type MantineColor,
  MantineProvider,
  Stack,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconBrandGithub,
  IconBuildingEstate,
  IconCode,
  IconDeviceGamepad,
  IconSchool,
} from "@tabler/icons-react";
import { type ReactNode } from "react";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";

import { experimentUrl } from ".";
import bevySpritesheetAnimationImage from "./assets/bevy_spritesheet_animation.gif";
import boyoVideo from "./assets/boyo.webm";
import brightPlateauVideo from "./assets/bright_plateau.webm";
import crtScreenVideo from "./assets/crtscreen.webm";
import harvestersFromOuterSpaceVideo from "./assets/harvesters_from_outer_space.webm";
import megadoVideo from "./assets/megado.webm";
import neonwiseVideo from "./assets/neonwise.webm";
import queltrisVideo from "./assets/queltris.webm";
import {
  createDefaultOptions,
  toEmoji,
} from "./experiments/text_to_emoji/TextToEmojiEditor";

const theme = createTheme({ fontFamily: "Cutive Mono" });

const fmdkdd = <a href="https://github.com/fmdkdd">fmdkdd</a>;

export function Home() {
  return (
    <MantineProvider theme={theme} defaultColorScheme="dark">
      <Stack p="xl" gap="xl">
        <Group justify="space-between" gap={0}>
          <Title order={3}>🌻 Merwan Achibet</Title>

          <Container
            mx="sm"
            style={{
              maxWidth: "100%",
              flexGrow: 1,
              height: "100%",
              backgroundImage:
                "linear-gradient(to right, var(--mantine-color-text) 10%, rgba(0, 0, 0, 0) 0%)",
              backgroundPosition: "0 1rem",
              backgroundSize: "10px 2px",
              backgroundRepeat: "repeat-x",
            }}
          >
            <span style={{ visibility: "hidden" }}>hi</span>
          </Container>

          <Group gap="xs">
            <a href="https://github.com/merwaaan">
              <Tooltip label="Code" withArrow>
                <ThemeIcon variant="transparent" c="gray">
                  <IconBrandGithub />
                </ThemeIcon>
              </Tooltip>
            </a>

            <a href="https://lindale.io/">
              <Tooltip label="Work" withArrow>
                <ThemeIcon variant="transparent" c="gray">
                  <IconBuildingEstate />
                </ThemeIcon>
              </Tooltip>
            </a>

            <a href="https://scholar.google.fr/citations?user=bQQrH0YAAAAJ&hl=fr">
              <Tooltip label="Research" withArrow>
                <ThemeIcon variant="transparent" c="gray">
                  <IconSchool />
                </ThemeIcon>
              </Tooltip>
            </a>
          </Group>
        </Group>

        <Grid align="stretch">
          <Grid.Col span={4}>
            <Thumbnail
              title="Old CRT screen shader"
              contents={
                <Desc>
                  Emulates all kinds of noises, perturbations, aberrations, and
                  a bit of nostalgia.
                </Desc>
              }
              actions={[
                { type: "play", url: experimentUrl("crt-old-screen-shader") },
              ]}
            >
              <Center>
                <Video url={crtScreenVideo} />
              </Center>
            </Thumbnail>
          </Grid.Col>

          <Grid.Col span={8}>
            <Thumbnail
              title="Text to emoji generator"
              contents={<Desc>Write text, get emojis 🍋</Desc>}
              actions={[
                { type: "play", url: experimentUrl("text-to-emoji-generator") },
              ]}
            >
              <Center
                h="100%"
                style={{ whiteSpace: "pre", textWrap: "nowrap" }}
              >
                <Marquee>
                  {toEmoji(
                    " hello salut hi",
                    [
                      { letter: "🌻", background: "🌿" },
                      { letter: "🔥", background: "☁️" },
                      { letter: "👽", background: "✨" },
                    ],
                    {
                      ...createDefaultOptions(),
                      direction: "horizontal",
                      fillSpacesWith: "nothing",
                      applyStylesOn: "word",
                    }
                  )}
                </Marquee>
              </Center>
            </Thumbnail>
          </Grid.Col>

          <Grid.Col span={4}>
            <Thumbnail
              title="bevy_spritesheet_animation"
              contents={
                <Desc>
                  The bevy game engine is cool and all, but it doesn't support
                  spritesheet-based animations out of the box. This plugin makes
                  it easy to compose and play animation clips.
                </Desc>
              }
              actions={[
                {
                  type: "code",
                  url: "https://github.com/merwaaan/bevy_spritesheet_animation",
                },
              ]}
            >
              <Image
                src={bevySpritesheetAnimationImage}
                h="100%"
                fit="contain"
                style={{ imageRendering: "pixelated" }}
              />
            </Thumbnail>
          </Grid.Col>

          <Grid.Col span={4}>
            <Thumbnail
              title="Harvesters From Outer Space"
              contents={
                <Description>
                  <Desc>
                    A 72-hours game jam of the third kind made for Ludum Dare 52
                    with {fmdkdd}.
                  </Desc>
                  <Desc>Capture cows. Avoid tanks.</Desc>
                </Description>
              }
              actions={[
                {
                  type: "play",
                  url: "https://merwaaan.github.io/ld52/",
                },
                {
                  type: "code",
                  url: "https://github.com/merwaaan/ld52",
                },
              ]}
            >
              <Center>
                <Video url={harvestersFromOuterSpaceVideo} />
              </Center>
            </Thumbnail>
          </Grid.Col>

          <Grid.Col span={4}>
            <Thumbnail
              title="NEONWISE '89"
              contents={
                <Description>
                  <Desc>
                    A game made in 72 hours for Ludum Dare 47 with {fmdkdd}.
                  </Desc>
                  <Desc>
                    Avoid obstacles, squiggle yourself into tight passages,
                    enjoy the neon buzz.
                  </Desc>
                </Description>
              }
              actions={[
                {
                  type: "play",
                  url: "https://0xc0de.fr/ld47/",
                },
                {
                  type: "code",
                  url: "https://github.com/fmdkdd/ld47",
                },
              ]}
            >
              <Center>
                <Video url={neonwiseVideo} />
              </Center>
            </Thumbnail>
          </Grid.Col>

          <Grid.Col span={4}>
            <Thumbnail
              title="QUELTRIS"
              contents={
                <Description>
                  <Desc>
                    A 72-hours game jam made for Ludum Dare 43 with {fmdkdd}.
                  </Desc>
                  <Desc>QUELTRIS DEMANDS SACRIFICES.</Desc>
                </Description>
              }
              actions={[
                {
                  type: "play",
                  url: "https://0xc0de.fr/ld43/",
                },
                {
                  type: "code",
                  url: "https://github.com/fmdkdd/ld43",
                },
                {
                  type: "custom",
                  label: "Post-Mortem",
                  url: "https://0xc0de.fr/2018/12/21/ld43-post-mortem.html",
                },
              ]}
            >
              <Center>
                <Video url={queltrisVideo} />
              </Center>
            </Thumbnail>
          </Grid.Col>

          <Grid.Col span={4}>
            <Thumbnail
              title="Bright Plateau"
              contents={
                <Description>
                  <Desc>
                    A puzzle game made in 72 hours for Ludum Dare 39 with{" "}
                    {fmdkdd}.
                  </Desc>
                  <Desc>
                    Power up houses by placing various kinds of generators.
                    Power must stay up by day and by night.
                  </Desc>
                </Description>
              }
              actions={[
                {
                  type: "play",
                  url: "https://0xc0de.fr/ld39/",
                },
                {
                  type: "code",
                  url: "https://github.com/fmdkdd/ld39",
                },
                {
                  type: "custom",
                  label: "Post-Mortem",
                  url: "https://0xc0de.fr/2017/08/08/ludum-dare-post-mortem.html",
                },
              ]}
            >
              <Center>
                <Video url={brightPlateauVideo} />
              </Center>
            </Thumbnail>
          </Grid.Col>

          <Grid.Col span={4}>
            <Thumbnail
              title="Megado"
              contents={
                <Description>
                  <Desc>A Sega Genesis/Mega Drive emulator in C.</Desc>
                  <Desc>Supports rewinding and a few debugging features.</Desc>
                  <Desc>Awesome audio emulation by my sidekick {fmdkdd}.</Desc>
                </Description>
              }
              actions={[
                { type: "code", url: "https://github.com/merwaaan/megado" },
              ]}
            >
              <Center>
                <Video url={megadoVideo} />
              </Center>
            </Thumbnail>
          </Grid.Col>

          <Grid.Col span={4}>
            <Thumbnail
              title="Boyo"
              contents={
                <Description>
                  <Desc>A Nintendo Game Boy emulator in JavaScript.</Desc>
                  <Desc>
                    My first serious emulator project. Might sound super-cheesy
                    but the first time the intro sequence of Link's Awakening
                    played after months of work is super special to me.
                  </Desc>
                </Description>
              }
              actions={[
                //{ type: "play", url: "TODO" },
                { type: "code", url: "https://github.com/merwaaan/boyo.js" },
              ]}
            >
              <Center>
                <Video url={boyoVideo} />
              </Center>
            </Thumbnail>
          </Grid.Col>
        </Grid>
      </Stack>
    </MantineProvider>
  );
}

function Thumbnail(props: {
  children: ReactNode;
  title: string;
  contents?: ReactNode;
  actions?: (
    | { type: "play"; url: string }
    | { type: "code"; url: string }
    | {
        type: "custom";
        label: string;
        icon?: ReactNode;
        color?: MantineColor;
        url: string;
      }
  )[];
}) {
  return (
    <Card shadow="sm" padding="lg" withBorder h="100%">
      <Card.Section flex="1">{props.children}</Card.Section>

      <Group justify="space-between" mt="md" mb="xs">
        <Text fz="h4" fw={700}>
          {props.title}
        </Text>

        <Group gap="xs">
          {props.actions?.map((action, actionIndex) => {
            const label =
              action.type == "play"
                ? "Play"
                : action.type == "code"
                ? "Code"
                : action.label;

            const icon =
              action.type == "play" ? (
                <IconDeviceGamepad size={22} />
              ) : action.type == "code" ? (
                <IconCode size={22} />
              ) : (
                action.icon
              );

            const color =
              action.type == "play"
                ? "pink"
                : action.type == "code"
                ? "lime"
                : action.color;

            return (
              <Link key={actionIndex} to={action.url}>
                <Button
                  leftSection={icon}
                  size="compact-sm"
                  variant="outline"
                  color={color}
                >
                  {label}
                </Button>
              </Link>
            );
          })}
        </Group>
      </Group>

      {props.contents}
    </Card>
  );
}

function Description(props: { children: ReactNode }) {
  return <Stack>{props.children}</Stack>;
}

function Desc(props: { children: ReactNode }) {
  return (
    <Text size="sm" c="dimmed">
      {props.children}
    </Text>
  );
}

function Video(props: { url: string }) {
  return (
    <video autoPlay loop muted width="100%">
      <source src={props.url} type="video/webm" />
    </video>
  );
}
