import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import slug from "slug";

import { OldScreen } from "./experiments/crt_shader/OldScreen";
import { TextToEmojiEditor } from "./experiments/text_to_emoji/TextToEmojiEditor";
import { Home } from "./Home";

const experiments = {
  "crt-old-screen-shader": (
    <div style={{ width: "100vw", height: "100vh" }}>
      <OldScreen showControls={false} />
    </div>
  ),
  "text-to-emoji-generator": <TextToEmojiEditor />,
};

export function experimentUrl(id: keyof typeof experiments): string {
  return `experiments/${slug(id)}`;
}

const router = createBrowserRouter([
  { path: "/", element: <Home />, errorElement: <ErrorPage /> },

  ...Object.entries(experiments).map(([experimentId, experiment]) => ({
    path: experimentUrl(experimentId),
    element: experiment,
  })),
]);

const container = document.getElementById("app");
if (container) {
  createRoot(container).render(<RouterProvider router={router} />);
}

// TODO strict mode

function ErrorPage() {
  return <h1>404 🍊</h1>;
}
